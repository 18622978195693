import $ from 'jquery';
import Viewport from './Viewport';

export default class MobileMenu {
  constructor(element) {
    this.element = element;
    this.$roots = this.getRootMenus();
    this.onClick = this.onClick.bind(this);
    this.displayActiveMenus();
    this.listen();
  }

  getRootMenus() {
    return $(this.element).find('a.depth-1');
  }

  /* eslint-disable class-methods-use-this */
  onClick(event) {
    const shouldExpand = $(event.target).is('.menu-link');
    if (Viewport.mobileState && shouldExpand) {
      $(event.currentTarget).toggleClass('expand');
      event.preventDefault();
    }
  }

  displayActiveMenus() {
    $('.active-trail a.depth-1:not(.active)', this.element).addClass('expand');
  }

  listen() {
    this.$roots.on('click', this.onClick);
  }
}
