import $ from 'jquery';
import TickTock from 'tick-tock';

const settings = {
  delay: 400,
  selectors: {
    menu: '.menu-icon',
    search: '.search-icon',
    text: '#searchButton',
    input: '#theSearch',
  },
};

export default class Toolbar {
  constructor(element, context) {
    this.context = context;
    this.element = element;
    this.$element = $(element);
    this.settings = settings;
    this.timer = new TickTock();
    this.$body = this.getBody();
    this.$menuBtn = this.getBtn('menu');
    this.$searchBtn = this.getBtn('search');
    this.$searchBox = this.getBox(context);
    this.searchText = this.getText(context);
    this.menuHandler = this.menuHandler.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
    this.resetSearchInput = this.resetSearchInput.bind(this);
    this.listen();
  }

  getBody() {
    return $(document.body, this.context);
  }

  getBtn(type) {
    return $(this.settings.selectors[type], this.element);
  }

  getText(context) {
    const $searchText = $(this.settings.selectors.text, context);
    // clear out the value since we don't want it displayed
    const searchText = $searchText.val();
    $searchText.val('');
    return searchText;
  }

  getBox(context) {
    const $input = $(this.settings.selectors.input, context);
    $input.val('');
    return $input;
  }

  menuHandler() {
    return this.$body.is('.menu-active') ? this.menuOff() : this.menuOn();
  }

  searchHandler() {
    return this.$body.is('.search-active') ? this.searchOff() : this.searchOn();
  }

  menuOff() {
    this.$body.removeClass('menu-active');
  }

  menuOn() {
    this.$body.addClass('menu-active');
  }

  searchOff() {
    this.$body.removeClass('search-active');
    // Cancel any search input timeouts that may be running.
    this.timer.clear('focus');
    this.timer.clear('typing');
  }

  searchOn() {
    this.$body.addClass('search-active');
    this.$searchBox.attr('placeholder', '');
    // Focus the search box after a small delay.
    this.timer.setTimeout('focus', () => {
      this.typeOutPlaceholderText();
    }, 750);
  }

  typeOutPlaceholderText() {
    let i = 0;
    if (!this.$searchBox.val().length) {
      this.timer.setInterval('typing', () => {
        if (i <= this.searchText.length) {
          this.$searchBox.attr('placeholder', this.searchText.substr(0, i)).focus();
          i += 1;
        } else {
          this.timer.clear('typing');
        }
      }, 60);
    }
  }

  resetSearchInput() {
    // This is a workaround to overcome the default dd-search.js search
    // input scripted behaviors.
    if (this.searchText === this.$searchBox.val()) {
      this.$searchBox.val('');
    }
  }

  listen() {
    this.$menuBtn.bind('click', this.menuHandler);
    this.$searchBtn.bind('click', this.searchHandler);

    // Listening so that we can override previously set scripted behaviors.
    this.$searchBox.bind('blur focus', this.resetSearchInput);
  }
}
