import $ from 'jquery';
import Viewport from './Viewport';

function TermNavigation(element) {
  this.element = element;
  this.$element = $(element);
  this.$menus = this.getMenus();
  this.onHeaderClick = $.proxy(this.onHeaderClick, this);
  this.setCurrentState();
  this.listen();
  this.ready();
}

TermNavigation.prototype = {
  getMenus() {
    const $menus = this.$element.find('.block-content .expanded');
    const $filteredMenus = $menus.has('li a');
    $menus.removeClass('expanded');
    $filteredMenus.addClass('expanded');
    return $filteredMenus;
  },
  onHeaderClick(event) {
    if (Viewport.mobileState) {
      this.$element.toggleClass('expanded');
      event.preventDefault();
    }
  },
  onMenuClick(event) {
    const clickedActivator = $(event.target).is('.menu-link');
    if (clickedActivator) {
      $(this).parent().toggleClass('expanded collapsed');
      event.preventDefault();
    }
  },
  setCurrentState() {
    // set all to collapsed other than the active items
    this.$menus.not('.active-trail').toggleClass('expanded collapsed');
  },
  listen() {
    // Add a click handler to control each submenu's visibility
    this.$menus.find(' > a').bind('click', this.onMenuClick);
    // Add a click handler to control the mobile dropdown menu
    this.$element.find('.block-title a').bind('click', this.onHeaderClick);
  },
  ready() {
    this.$element.addClass('ready');
  },
};

export default TermNavigation;
