import Headroom from 'headroom.js';

// Headroom settings
const settings = {
  // vertical offset in px before element is first unpinned
  offset: 80,
  // scroll tolerance in px before state changes
  tolerance: 5, // or {down: 0, up: 0},
  // css classes to apply
  classes: {
    // when element is initialised
    initial: 'headroom',
    // when scrolling up
    pinned: 'locked',
    // when scrolling down
    unpinned: 'unlocked',
    // when above offset
    top: 'top',
    // when below offset
    notTop: 'not-top',
    // when at bottom of scoll area
    bottom: 'bottom',
    // when not at bottom of scroll area
    notBottom: 'not-bottom',
  },
  // callback when pinned, `this` is headroom object
  onPin() {},
  // callback when unpinned, `this` is headroom object
  onUnpin() {},
  // callback when above offset, `this` is headroom object
  onTop() {},
  // callback when below offset, `this` is headroom object
  onNotTop() {},
  // callback at bottom of page, `this` is headroom object
  onBottom() {},
  // callback when moving away from bottom of page, `this` is headroom object
  onNotBottom() {},
};

export default class Header {
  constructor(element) {
    // Create a new Headroom instance
    const headroom = new Headroom(element, settings);
    // Initialize this Headroom instance
    headroom.init();
    // Return back the new instance
    return headroom;
  }
}
