import $ from 'jquery';
import Sticky from './Sticky';
import Header from './Header';
import Toolbar from './Toolbar';
import MenuExport from './MenuExport';
import SmartFooter from './SmartFooter';
import TermNavigation from './TermNavigation';

$(document).ready(() => {
  const navigation = document.getElementById('navigation');

  // Fetch navigation from host site.
  navigation.MenuExport = new MenuExport(navigation);

  // Sticky sidebars
  $('.page-sidebar').each((i, element) => {
    if (!element.Sticky) {
      element.Sticky = new Sticky(element);
    }
  });

  // Controls the states of the menu and search states
  $('.toolbar').each((i, element) => {
    if (!element.Toolbar) {
      element.Toolbar = new Toolbar(element);
    }
  });

  // Controls the page overlay display
  $('.overlay').bind('click', () => {
    $(document.body).removeClass('menu-active search-active');
  });

  // Fixed Header using Headroom.js
  $('.headroom').each((i, element) => {
    if (!element.Header) {
      element.Header = new Header(element);
    }
  });

  // Smart Footer
  $('.page-footer').each((i, element) => {
    const $footprint = $('.footprint');
    if (!element.SmartFooter && $footprint.length) {
      element.SmartFooter = new SmartFooter(element, $footprint);
    }
  });

  // Term Navigation
  $('.term-nav').each((i, element) => {
    if (!element.TermNavigation) {
      element.TermNavigation = new TermNavigation(element);
    }
  });
});
