import $ from 'jquery';
import Change from './Change';

// const OFFSET = 72 + 35; // header height plus top offset of sidebars
const OFFSET = 35; // only top offset of sidebars (header scrolls)

export default class Sticky {
  constructor(element) {
    this.element = element;
    this.$element = $(element);
    this.register();
  }

  register() {
    Change.onChange(this.update.bind(this));
  }

  update() {
    if (window.innerHeight - OFFSET > this.$element.height()) {
      this.$element.addClass('sticky');
    } else {
      this.$element.removeClass('sticky');
    }

    return this;
  }
}
