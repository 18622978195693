import $ from 'jquery';
import Menu from './Menu';
import MobileMenu from './MobileMenu';

const URL = 'https://academics.sierracollege.edu';
const targetSelector = '#block-nice_menus-3';

export default class ExportMenu {
  constructor(element) {
    this.element = element;
    this.fail = this.fail.bind(this);
    this.success = this.success.bind(this);

    const url = new Request(URL);
    const options = {
      mode: 'cors',
      method: 'GET',
      cache: 'default',
    };

    // Request homepage.
    fetch(url, options)
      .then(this.validate)
      .then(this.success)
      .catch(this.fail);
  }

  // Validate response from our request.
  /* eslint-disable class-methods-use-this */
  validate(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.text();
  }

  // Display an error message if the request failed.
  fail(error) {
    this.error = error;
  }

  success(result) {
    const $content = $(result).find(targetSelector);
    $content.find('a').filter('[href^="/"]').each((i, component) => {
      $(component).attr('href', (ii, value) => `${URL}${value}`);
    });
    $content.appendTo(this.element);

    // Add functionality to the menu.
    if (!this.element.Menu && !this.element.MobileMenu) {
      this.element.Menu = new Menu(this.element);
      this.element.MobileMenu = new MobileMenu(this.element);
    }
  }
}
