import $ from 'jquery';
import TickTock from 'tick-tock';
import Viewport from './Viewport';

const settings = {
  hoverInit: 250,
  hoverDelay: 250,
  selector: 'li.menuparent',
};

export default class Menu {
  constructor(element) {
    this.element = element;
    this.$element = $(element);
    this.settings = settings;
    this.$menus = this.getMenus();
    this.over = this.over.bind(this);
    this.out = this.out.bind(this);
    this.listen();
  }

  getMenus() {
    return this.$element.find(this.settings.selector);
  }

  over(event) {
    const $target = $(event.currentTarget);
    if (!Viewport.mobileState) {
      event.currentTarget.timer.clear('hide');
      event.currentTarget.timer.setTimeout('show', () => {
        $target.addClass('show');
      }, this.settings.hoverInit);
    }
  }

  out(event) {
    const $target = $(event.currentTarget);
    if (!Viewport.mobileState) {
      event.currentTarget.timer.clear('show');
      event.currentTarget.timer.setTimeout('hide', () => {
        $target.removeClass('show');
      }, this.settings.hoverInit);
    }
  }

  listen() {
    this.$menus.each((i, element) => {
      element.timer = new TickTock();
    });

    this.$menus.removeClass('show')
      .on('mouseover focusin', this.over)
      .on('mouseout focusout', this.out);
  }
}
