import $ from 'jquery';

const breakpoint = 1200;

class Viewport {
  constructor() {
    this.change = this.change.bind(this);
    this.mobileState = this.getCurrentState();
    this.ticking = false;
    this.addListeners();
  }

  setCurrentState(state) {
    this.mobileState = state;
  }

  /* eslint-disable class-methods-use-this */

  getCurrentState() {
    return window.innerWidth < breakpoint;
  }

  update() {
    this.ticking = false;
    const currentState = this.getCurrentState();
    const previousState = this.mobileState;

    // Announce screen has changed
    if (this.changeCallbacks) {
      this.changeCallbacks.fire();
    }

    // Check to see if the view state has changed
    if (currentState !== previousState) {
      this.setCurrentState(currentState);

      // Announce screen has changed to mobile mode
      if (this.mobileCallbacks && this.mobileState) {
        this.mobileCallbacks.fire();
      }

      // Announce screen has changed to desktop mode
      if (this.desktopCallbacks && !this.mobileState) {
        this.desktopCallbacks.fire();
      }
    }
  }

  change() {
    this.requestTick();
  }

  requestTick() {
    if (!this.ticking) {
      requestAnimationFrame(this.update.bind(this));
      this.ticking = true;
    }
  }

  onMobileChange(callback) {
    if (!this.mobileCallbacks) {
      this.mobileCallbacks = $.Callbacks('memory');
    }
    this.mobileCallbacks.add(callback);
    return this;
  }

  onDesktopChange(callback) {
    if (!this.desktopCallbacks) {
      this.desktopCallbacks = $.Callbacks('memory');
    }
    this.desktopCallbacks.add(callback);
    return this;
  }

  onChange(callback) {
    if (!this.changeCallbacks) {
      this.changeCallbacks = $.Callbacks('memory');
    }
    this.changeCallbacks.add(callback);
    return this;
  }

  addListeners() {
    $(window).on('resize orientationchange', this.change);
  }
}

export default new Viewport();
