import $ from 'jquery';
import Change from './Change';

export default class Footer {
  constructor(element, $footprint) {
    this.$element = $(element);
    this.$footprint = $footprint;
    this.register();
    this.update();
    this.ready();
  }

  register() {
    Change.onChange(this.update.bind(this));
  }

  update() {
    this.$footprint.height(this.$element.outerHeight());
  }

  ready() {
    this.$element.addClass('ready');
  }
}
