import $ from 'jquery';

class Change {
  constructor() {
    this.change = this.change.bind(this);
    this.ticking = false;
    this.listen();
  }

  update() {
    this.ticking = false;
    if (this.callbacks) {
      this.callbacks.fire();
    }
  }

  change() {
    this.request();
  }

  request() {
    if (!this.ticking) {
      requestAnimationFrame(this.update.bind(this));
      this.ticking = true;
    }
  }

  onChange(callback) {
    if (!this.callbacks) {
      this.callbacks = $.Callbacks('memory');
    }
    this.callbacks.add(callback);
    return this;
  }

  listen() {
    $(window).on('scroll resize orientationchange', this.change);
  }
}

export default new Change();
